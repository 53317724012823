// Font Awesome
$fa-font-path: "~font-awesome/fonts" !default;
@import "~font-awesome/scss/font-awesome";

// Ionicons
$ionicons-font-path: "~ionicons/fonts" !default;
@import "~ionicons/scss/ionicons";

// Owl Carousel
//@import "~owl.carousel/dist/assets/owl.carousel";
//@import "~owl.carousel/dist/assets/owl.theme.default";

// AdminLTE
@import "templatemo";

// jvectormap
@import "~jvectormap/jquery-jvectormap";

// Full Calendar
@import "~fullcalendar/dist/fullcalendar";

// Date picker
@import "~bootstrap-datepicker/dist/css/bootstrap-datepicker";
@import "~bootstrap-daterangepicker/daterangepicker";
@import "~eonasdan-bootstrap-datetimepicker/build/css/bootstrap-datetimepicker.css";

.bs-callout {
    padding: 20px;
    margin: 20px 0;
    border: 1px solid #eee;
    border-left-width: 5px;
    border-radius: 3px;
}
.bs-callout h4 {
    margin-top: 0;
    margin-bottom: 5px;
}
.bs-callout p:last-child {
    margin-bottom: 0;
}
.bs-callout code {
    border-radius: 3px;
}
.bs-callout+.bs-callout {
    margin-top: -5px;
}
.bs-callout-default {
    border-left-color: #777;
}
.bs-callout-default h4 {
    color: #777;
}
.bs-callout-primary {
    border-left-color: #428bca;
}
.bs-callout-primary h4 {
    color: #428bca;
}
.bs-callout-success {
    border-left-color: #5cb85c;
}
.bs-callout-success h4 {
    color: #5cb85c;
}
.bs-callout-danger {
    border-left-color: #d9534f;
}
.bs-callout-danger h4 {
    color: #d9534f;
}
.bs-callout-warning {
    border-left-color: #f0ad4e;
}
.bs-callout-warning h4 {
    color: #f0ad4e;
}
.bs-callout-info {
    border-left-color: #5bc0de;
}
.bs-callout-info h4 {
    color: #5bc0de;
}

.separator {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #bbb;
    margin: 1em 0;
    padding: 0;
}
